<!--
 * @Description: 工单
 * @Author: ChenXueLin
 * @Date: 2021-12-22 13:39:13
 * @LastEditTime: 2022-07-12 09:18:36
 * @LastEditors: ChenXueLin
-->
<template>
  <div v-bind="$attrs">
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="workNo">
        <el-input
          v-model="searchForm.workNo"
          placeholder="工单号"
          title="工单号"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="sceneName">
        <el-input
          v-model="searchForm.sceneName"
          placeholder="场景名称"
          title="场景名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
        <el-button type="primary" @click="addService">创建服务信息</el-button>
      </el-form-item>
    </el-form>

    <!-- 搜索头部 end -->
    <!-- 表格 start -->
    <section class="table-wrapper">
      <!-- :height="resizeViewHeight + 'px'" -->
      <el-table
        border
        :height="tableHeight"
        :data="tableData"
        highlight-current-row
        ref="elTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'workNo'">
              <el-button type="text" @click="toTaskDetail(row)">{{
                row[column.fieldName]
              }}</el-button>
            </div>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="80">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList(scope.row)"
              @command="handleOperate($event, scope.row)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <el-dialog
      v-dialogDrag
      title="添加服务信息"
      :visible.sync="addDioalog"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="add-dialog"
    >
      <el-form
        ref="addFillForm"
        label-width="120px"
        :model="addFillForm"
        :rules="addFillFormRules"
      >
        <el-form-item label="一级服务类型" prop="firstServerCode">
          <e6-vr-select
            v-model="addFillForm.firstServerCode"
            :data="firstTypeList"
            placeholder="一级服务类型"
            title="一级服务类型"
            clearable
            :props="{
              id: 'codeId',
              label: 'codeName'
            }"
            @change="handleChange"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="二级服务类型名称" prop="secondServerCode">
          <e6-vr-select
            v-model="addFillForm.secondServerCode"
            :data="secondTypeList"
            placeholder="二级服务类型名称"
            title="二级服务类型名称"
            clearable
            :props="{
              id: 'codeId',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="三级服务类型名称" prop="thirdServerCode">
          <e6-vr-select
            v-model="addFillForm.thirdServerCode"
            :data="thirdTypeList"
            placeholder="三级服务类型名称"
            title="三级服务类型名称"
            clearable
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="四级服务类型名称" prop="fourthServiceCode">
          <e6-vr-select
            v-model="addFillForm.fourthServiceCode"
            :data="fourthTypeList"
            placeholder="四级服务类型名称"
            title="四级服务类型名称"
            clearable
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="联系时间" prop="serverTimeTimestamp">
          <el-date-picker
            v-model="addFillForm.serverTimeTimestamp"
            type="datetime"
            placeholder="选择日期"
            value-format="timestamp"
            popper-class="special"
            default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="服务内容" prop="serverDetails">
          <el-input
            placeholder="服务内容"
            v-model="addFillForm.serverDetails"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
    <ChangeOrder
      :visible.sync="changeOrderDialog"
      :serviceId="serviceId"
      :workSecondClass="workSecondClass"
    ></ChangeOrder>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getAllTask } from "@/api";
import listPage from "@/mixins/list-page";

import { printError } from "@/utils/util";
import { getFirstType, findDownList, batchInsert, getSecondType } from "@/api";
import ChangeOrder from "./components/changeOrder.vue";
export default {
  name: "workOrderList",
  components: { ChangeOrder },
  props: [
    "activeName",
    "callType",
    "customerNumber",
    "mainUniqueId",
    "corpInfo",
    "corpId",
    "tableHeight"
  ],
  data() {
    return {
      searchForm: {
        sceneName: "", //场景名称
        thirdClassId: "", //商品名称
        equipCode: "", //设备编号
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "工单号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneNames",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workTypeName",
          display: true,
          fieldLabel: "工单类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workSecondClassName",
          display: true,
          fieldLabel: "工单二级类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workStatusName",
          display: true,
          fieldLabel: "工单状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      taskIdAndNum: [],
      /*****添加服务信息******/
      addFillForm: {
        firstServerCode: "", //一级
        secondServerCode: "", //二级
        thirdServerCode: "", //三级
        fourthServiceCode: "", //四级
        serverDetails: "", //服务内容
        serverTimeTimestamp: new Date().getTime() //联系时间
      },
      addFillFormRules: {
        firstServerCode: [
          {
            required: true,
            message: "请选择一级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        secondServerCode: [
          {
            required: true,
            message: "请选择二级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        thirdServerCode: [
          {
            required: true,
            message: "请选择三级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        fourthServiceCode: [
          {
            required: true,
            message: "请选择四级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        serverTimeTimestamp: [
          {
            required: true,
            message: "请选择联系时间",
            trigger: ["blur", "change"]
          }
        ],
        serverDetails: [
          {
            required: true,
            message: "请输入服务内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      firstTypeList: [], //一级服务类型
      secondTypeList: [], //二级服务类型
      thirdTypeList: [], //三级
      fourthTypeList: [], //四级
      serviceResultList: [], //服务结果
      addDioalog: false,
      //转工单
      changeOrderDialog: false,
      workSecondClass: "", //1维修7咨询回复
      serviceId: "" //服务单id
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "four" && this.corpId) {
          this.queryList();
        }
      }
    },
    corpId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal && this.activeName == "four") {
          this.queryList();
        }
      }
    },
    addDioalog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          this.addFillForm.serverTimeTimestamp = new Date().getTime();
        }
      }
    }
  },
  methods: {
    // 搜索
    handleSearch() {
      if (!this.corpId) {
        this.$message.warning("请选择客户");
        return;
      }
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    //去任务单详情
    toTaskDetail(row) {
      let workNo = row.workNo;
      if (!workNo) {
        this.$message.warning("无关联单号");
        return;
      }
      if (workNo.indexOf("RWD") > -1) {
        this.routerPush({
          name: "taskListManage/detail",
          params: {
            refresh: true,
            taskId: row.taskId
          }
        });
      }
      if (workNo.indexOf("FWD") > -1) {
        this.routerPush({
          name: "serviceListManage/serviceDetail",
          params: {
            refresh: true,
            serviceId: row.taskId
          }
        });
      }
    },
    //查询二级服务类型
    handleChange() {
      this.addFillForm.secondServerCode = "";
      this.getSecondType();
    },
    //获取二级服务类型
    async getSecondType() {
      try {
        let res = await getSecondType({
          firstServerCode: this.addFillForm.firstServerCode
        });
        this.secondTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    async initData() {
      try {
        let promiseList = [
          getFirstType({ whether: 1 }),
          findDownList(["serviceType", "serviceResult", "fourthService"])
        ];
        let [firstTypeRes, downRes] = await Promise.all(promiseList);
        //问题一级类型
        this.firstTypeList = this.getFreezeResponse(firstTypeRes, {
          path: "data"
        });
        //三级服务类型
        this.thirdTypeList = this.getFreezeResponse(downRes, {
          path: "data.serviceType"
        });
        //四级服务类型
        this.fourthTypeList = this.getFreezeResponse(downRes, {
          path: "data.fourthService"
        });
        //服务结果
        this.serviceResultList = this.getFreezeResponse(downRes, {
          path: "data.serviceResult"
        });
        console.log(this.$parent.corpInfo, "corpInfo");
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询全部工单
    async queryList() {
      try {
        this.loading = true;
        let res = await getAllTask({ ...this.searchForm, corpId: this.corpId });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理多选
    handleSelectionChange(columns) {
      this.taskIdAndNum = columns.map(item => {
        return Object.assign(
          {},
          {
            taskNum: item.workNo,
            taskId: item.taskId
          }
        );
      });
    },
    addService() {
      if (!this.taskIdAndNum.length) {
        this.$message.warning("请选择任务单");
        return;
      }
      if (!this.corpId) {
        this.$message.warning("请选择客户");
        return;
      }
      this.addDioalog = true;
    },
    //确定添加
    handleConfirm() {
      this.$refs.addFillForm.validate(valid => {
        if (valid) {
          this.batchInsertReq();
        }
      });
    },
    //添加服务详情
    async batchInsertReq() {
      try {
        this.loading = true;
        let res = await batchInsert({
          ...this.addFillForm,
          customerId: this.corpInfo.corpId,
          customerName: this.corpInfo.corpName,
          phoneCallName: this.corpInfo.peopleName, //来电人名称
          phoneTypeCode: this.callType, //来去电类型码值
          phoneNumber: this.customerNumber, //来电人号码
          record: this.mainUniqueId,
          taskIdAndNum: this.taskIdAndNum
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleClose();
          this.closeTag(this.$route);
          let visitedViews = this.visitedViews;
          this.routerPush({
            name: visitedViews.length
              ? visitedViews[visitedViews.length - 1].name
              : "workOrderManage/allWorkOrder",
            params: {
              refresh: false
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addFillForm = {
        firstServerCode: "", //一级
        secondServerCode: "", //二级
        thirdServerCode: "", //三级
        fourthServiceCode: "", //四级
        serverDetails: "", //服务内容
        serverTimeTimestamp: "" //联系时间
      };
      this.$refs.addFillForm.resetFields();
      this.addDioalog = false;
      this.taskIdAndNum = [];
      this.$refs.elTable.clearSelection();
    },
    // 获取页面操作权限
    getOperateList(row) {
      let operates = [];
      if (
        row.workType == 1 &&
        (row.workSecondClass == 7 || row.workSecondClass == 1)
      ) {
        //咨询回复单，1维修服务单
        operates = [
          {
            label: "转单",
            id: 1
          }
        ];
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.changeOrderDialog = true;
        this.workSecondClass = row.workSecondClass;
        this.serviceId = row.taskId;
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
/deep/ .add-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-form {
      .el-input {
        width: 260px;
      }
      .el-textarea {
        width: 260px;
      }
    }
  }
}
</style>
