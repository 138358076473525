<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-05-10 10:11:20
 * @LastEditTime: 2022-07-01 09:25:45
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 转工单弹框 -->
  <el-dialog
    v-dialogDrag
    title="转工单"
    :visible="visible"
    width="450px"
    :close-on-click-modal="false"
    :before-close="
      () => {
        updatevisible(false);
      }
    "
    :element-loading-background="loadingBackground"
    v-loading="loading"
    custom-class="custom-dialog"
  >
    <div class="dialog-content">
      <div class="work-order-list">
        <template>
          <div class="work-order-type">请选择要转工单的类型</div>
          <div class="list-content">
            <div
              class="work-order"
              v-for="(item, index) in taskList"
              :key="index"
            >
              <div
                class="item"
                :class="{
                  active: item.path == path
                }"
                @click="clickItem(item.path)"
              >
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="til">{{ item.title }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="updatevisible(false)">取消</el-button>
      <el-button type="primary" @click="toAddTask">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getReplyItems, getRepairService, getServiceBasicInfo } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "",
  components: {},
  data() {
    return {
      loading: false,
      /****转工单*****/
      taskList: [
        {
          title: "维修任务",
          type: 3,
          imgUrl: require("@/assets/images/weixiu.png"),
          path: "taskListManage/addRepairOrder"
        },
        {
          title: "重装任务",
          type: 6,
          imgUrl: require("@/assets/images/chongzhuang.png"),
          path: "taskListManage/addReinstallOrder"
        },

        {
          title: "巡检任务",
          type: 4,
          imgUrl: require("@/assets/images/xunjian.png"),
          path: "taskListManage/addInspectionOrder"
        }
      ],
      path: "",
      basicInfo: {}, //基本信息
      tableData: [] //任务项列表
    };
  },
  props: ["visible", "workSecondClass", "serviceId"],
  mixins: [base],
  computed: {
    changeTaskVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.updatevisible(v);
      }
    }
  },
  watch: {
    changeTaskVisible: {
      immediate: true,
      handler(val) {
        if (val && this.workSecondClass == 7) {
          this.getReplyItemsReq();
          //咨询回复
          this.taskList = [
            {
              title: "维修任务",
              type: 3,
              imgUrl: require("@/assets/images/weixiu.png"),
              path: "taskListManage/addRepairOrder"
            },
            {
              title: "重装任务",
              type: 6,
              imgUrl: require("@/assets/images/chongzhuang.png"),
              path: "taskListManage/addReinstallOrder"
            },

            {
              title: "巡检任务",
              type: 4,
              imgUrl: require("@/assets/images/xunjian.png"),
              path: "taskListManage/addInspectionOrder"
            }
          ];
          this.getServiceBasicInfoReq();
        }
        if (val && this.workSecondClass == 1) {
          this.getServiceBasicInfoReq();
          this.getRepairService();
          this.taskList = [
            {
              title: "维修任务",
              type: 3,
              imgUrl: require("@/assets/images/weixiu.png"),
              path: "taskListManage/addRepairOrder"
            }
          ];
        }
      }
    }
  },
  methods: {
    clickItem(path) {
      this.path = path;
    },
    //获取咨询回复任务项列表
    async getReplyItemsReq() {
      try {
        let res = await getReplyItems({
          serviceId: this.serviceId,
          pageIndex: 1,
          pageSize: 999
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询维修服务单详情任务项分页列表
    async getRepairService() {
      try {
        let res = await getRepairService({
          serviceId: this.serviceId,
          pageIndex: 1,
          pageSize: 999
        });
        this.tableData = res.data.array;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取基本信息
    async getServiceBasicInfoReq() {
      try {
        this.loading = true;
        let res = await getServiceBasicInfo({
          serviceId: this.serviceId
        });
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定转单
    toAddTask() {
      if (!this.path) {
        this.$message.warning("请选择要转单的类型");
        return;
      }
      let ids = this.tableData.map(item => {
        let obj = {
          equipId: item.equipId,
          id: item.id
        };
        return obj;
      });
      let equipList = JSON.stringify(ids);
      this.routerPush({
        name: this.path,
        params: {
          refresh: true,
          equipList,
          serviceId: this.serviceId,
          queryType: 1
        }
      });
      this.closeDialog();
    },
    // 关闭弹框
    closeDialog() {
      this.changeTaskVisible = false;
    },
    updatevisible(v) {
      this.$emit("update:visible", v);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.dialog-content {
  padding: 20px;
  box-sizing: border-box;
  .input-with-select {
    width: 240px;
  }
  .work-order-list {
    .work-order-type {
      font-size: 14px;
      color: #444446;
      // font-weight: 550;
    }
    .list-content {
      display: flex;
      flex-wrap: wrap;
      .work-order {
        margin-right: 10px;
        .item {
          width: 89px;
          height: 89px;
          border-radius: 5px;
          border: 1px solid #eae9ee;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          & > img {
            width: 40px;
            height: 37px;
          }
        }
        .active {
          border: 1px solid #46bfea;
        }
        .til {
          text-align: center;
          font-size: 14px;
          color: #676769;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
